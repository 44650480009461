
.feature-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This makes sure that the content is spaced evenly */
    min-height: 300px; /* Adjust based on your preference */
    transition: all 0.3s ease; /* Optional: for smooth resizing */
}

@media (max-width: 767px) {
    .feature-item {
       height: 45vh !important;
    }

   
        .carousel-caption-content {
            padding: 0 12px;
          
        }
        
        .carousel-caption h1 {
            font-size: 2rem; /* Adjust as needed */
        }

        .carousel-caption h4,
        .carousel-caption p {
            font-size: 1rem; /* Adjust as needed */
        }
    

    .carousel-control-prev,
    .carousel-control-next {
        filter: invert(100%);
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        background-color: rgba(0, 0, 0, 0.5); /* Optional: make controls stand out more */
    }


    
}
